
  import { computed, defineComponent, onBeforeUnmount, onMounted, onUnmounted, reactive, ref, watch } from 'vue';
  import { useMutation, useQuery, useResult } from '@vue/apollo-composable';
  import { useRoute } from 'vue-router';
  import { DateTime } from 'luxon';

  import gql from 'graphql-tag';

  import Constants from '@/constants';
import { useSecurityStore } from '@/modules/security/store';

  enum PhotoState {
    FRONT = 'front',
    BACK = 'back',
  };

  export default defineComponent({
    setup() {
      const route = useRoute();

      const securityStore = useSecurityStore();

      const annotationTarget = ref<HTMLElement | null>(null);

      const newAnnotations = ref<any[]>([]);

      const annotations = computed(() => {
        if (!file.value) {
          return [];
        }

        return [
          ...newAnnotations.value,
          ...file.value.annotations,
        ];
      });

      const objectIds = JSON.parse(window.localStorage.getItem('objects_done') ?? '[]');

      const objectDone = ref(objectIds.indexOf(route.params.objectId) !== -1);

      const edittingAnnotation = ref<any | undefined>(undefined);

      const photo = ref<HTMLElement | null>(null);

      const currentPhotoPosition = computed(() => {
        if (!flags.isMounted || !photo.value) {
          return {
            left: '0',
            top: '0',
          };
        }

        const { top, left } = photo.value.getBoundingClientRect();

        return {
          left,
          top,
        };
      });

      watch(objectDone, (value) => {
        const existing = JSON.parse(window.localStorage.getItem('objects_done') ?? '[]');

        if (value) {
          existing.push(route.params.objectId);
        } else {
          existing.splice(existing.indexOf(route.params.objectId), 1);
        }

        window.localStorage.setItem('objects_done', JSON.stringify(existing));

        saveMarkedDone({
          objectIds: JSON.stringify(existing),
        })
      });

      const onAddPersonCancel = (): void => {
        flags.annotation.isResizing = false;
        flags.annotation.isMoving = false;
        flags.annotation.isLocked = false;
        flags.annotation.hasMoved = false;
        flags.finishAnnotation = false;
        flags.isAnnotating = false;
      };

      const confirmAnnotationEdit = (): void => {
        updateAnnotation({
          id: edittingAnnotation.value.id,
          data: JSON.stringify({
            x: annotation.x,
            y: annotation.y,
            radius: annotation.radius,
            person_id: edittingAnnotation.value.data.person.id,
          }),
        });

        flags.annotation.isResizing = false;
        flags.annotation.isMoving = false;
        flags.annotation.isLocked = false;
        flags.annotation.hasMoved = false;

        flags.finishAnnotation = false;
        flags.isAnnotating = false;
      };

      const cancelAnnotationEdit = (): void => {
        flags.annotation.isResizing = false;
        flags.annotation.isMoving = false;
        flags.annotation.isLocked = false;
        flags.annotation.hasMoved = false;

        flags.finishAnnotation = false;
        flags.isAnnotating = false;
      };

      const annotation = reactive({
        radius: 96,
        lastX: 0,
        lastY: 0,
        x: 0,
        y: 0,
      });

      const flags = reactive({
        confirmAnnotationDelete: false,

        isEdittingAnnotation: false,
        finishAnnotation: false,
        isAnnotating: false,
        isMounted: false,
        isEnlarged: false,
        annotation: {
          isResizing: false,
          isMoving: false,
          isLocked: false,
          hasMoved: false,
        },
      });

      const descriptionSaved = ref(false);
      const description = ref('');

      // Load the object's data from the back end.
      const { result, loading, refetch } = useQuery(gql`
        query getObject($objectId: ID!) {
          object(id: $objectId) {
            description
            files {
              id
              width
              height
              annotations(types: [ PERSON ]) {
                id
                data {
                  ... on PersonAnnotation {
                    person {
                      id
                      firstNames
                      nickname
                      lastName
                      maidenName
                      bornAt
                    }
                    radius
                    x
                    y
                  }
                }
              }
            }
          }
        }
      `, {
        objectId: route.params.objectId,
      }, {
        fetchPolicy: 'no-cache',
      });

      const { onDone: onUpdateDescriptionDone, mutate: updateDescription } = useMutation(gql`
        mutation UpdateObject(
          $id: ID!
          $description: String
        ) {
          updateObject(
            id: $id
            input: {
              description: $description
            }
          ) {
            object {
              id
            }
          }
        }
      `);

      const { mutate: saveMarkedDone } = useMutation(gql`
        mutation SaveMarkedDone(
          $objectIds: String!
        ) {
          markDone(
            objectIds: $objectIds
          )
        }
      `);

      const { mutate: deleteAnnotation, onDone: deleteAnnotationDone } = useMutation(gql`
        mutation DeleteAnnotation(
          $id: ID!
        ) {
          deleteAnnotation(id: $id)
        }
      `);

      const { mutate: updateAnnotation, onDone: updateAnnotationDone } = useMutation(gql`
        mutation UpdateAnnotation(
          $id: ID!
          $data: JSON!
        ) {
          updateAnnotation(
            id: $id
            input: {
              data: $data
            }
          ) {
            annotation {
              id
            }
          }
        }
      `);

      const onPersonFound = (person: any): void => {
        if (flags.isEdittingAnnotation) {
          updateAnnotation({
            id: edittingAnnotation.value.id,
            data: JSON.stringify({
              x: annotation.x,
              y: annotation.y,
              radius: annotation.radius,
              person_id: person.id,
            }),
          });
        } else {
          createAnnotation({
            file: file.value.id,
            data: JSON.stringify({
              person_id: person.id,
              radius: annotation.radius,
              x: annotation.x,
              y: annotation.y,
            }),
          });
        }
      };

      const { onDone: onCreateAnnotationDone, mutate: createAnnotation } = useMutation(gql`
        mutation CreateAnnotation(
          $file: ID!
          $data: JSON!
        ) {
          createAnnotation(
            input: {
              file: $file
              type: PERSON
              data: $data
            }
          ) {
            annotation {
              id
              data {
                ... on PersonAnnotation {
                  person {
                    id
                    firstNames
                    nickname
                    lastName
                    maidenName
                    bornAt
                  }
                  radius
                  x
                  y
                }
              }
            }
          }
        }
      `);

      onCreateAnnotationDone(() => {
        flags.annotation.isResizing = false;
        flags.annotation.isMoving = false;
        flags.annotation.isLocked = false;
        flags.annotation.hasMoved = false;

        flags.finishAnnotation = false;
        flags.isAnnotating = false;

        refetch();
      });

      deleteAnnotationDone(() => {
        refetch();
      });

      updateAnnotationDone(() => {
        refetch();

        flags.annotation.isResizing = false;
        flags.annotation.isMoving = false;
        flags.annotation.isLocked = false;
        flags.annotation.hasMoved = false;

        flags.finishAnnotation = false;
        flags.isAnnotating = false;
      });

      const showDate = (date: string): string => {
        return DateTime.fromISO(date).setLocale('nl').toLocaleString(DateTime.DATE_FULL);
      };

      const onEditAnnotationClick = (which: any): void => {
        edittingAnnotation.value = which;

        annotation.radius = which.data.radius;
        annotation.lastX = which.data.x;
        annotation.lastY = which.data.y;
        annotation.x = which.data.x;
        annotation.y = which.data.y;

        flags.isEdittingAnnotation = true;
        flags.isAnnotating = true;
      };

      const annotationToDelete = ref<string | undefined>(undefined);

      const onDeleteAnnotationClick = (annotation: any): void => {
        annotationToDelete.value = annotation.id;

        flags.confirmAnnotationDelete = true;
      };

      const onDeleteAnnotation = ():  void => {
        deleteAnnotation({
          id: annotationToDelete.value,
        });

        flags.confirmAnnotationDelete = false;

        annotationToDelete.value = undefined;
      };

      onUpdateDescriptionDone(() => {
        descriptionSaved.value = true;

        window.setTimeout(() => {
          descriptionSaved.value = false;
        }, 1000);
      });

      const onDescriptionSubmit = (): void => {
        originalDescription = description.value;

        updateDescription({
          description: description.value,
          id: route.params.objectId,
        });
      };

      let originalDescription: string = '';
      let objectId: string = '';

      const object = useResult(result, null, (data) => {
        originalDescription = data.object.description ?? '';
        objectId = route.params.objectId as string;

        description.value = data.object.description ?? '';

        return data.object;
      });

      const photoState = ref<PhotoState>(PhotoState.FRONT);

      // ...

      /**
       * The size of the container element in which the file images are placed.
       */
      const fileContainerSize = ref<DOMRect | undefined>(undefined);

      /**
       * The HTML element of the container element in which the image files are
       * placed.
       */
      const fileContainer = ref<HTMLElement | null>(null);

      /**
       * The observer that watches the size of the `fileContainer'.
       */
      const fileContainerObserver = new ResizeObserver((entries) => {
        // TODO: Can this loop be eliminated?
        for (const entry of entries) {
          fileContainerSize.value = entry.contentRect;
        }
      });

      const file = computed(() => {
        return object.value.files[0];
      });

      /**
       * Called when the component is mounted.
       */
      onMounted(() => {
        flags.isMounted = true;

        // Register the observer that watches the size of the `fileContainer'.
        if (fileContainer.value) {
          fileContainerObserver.observe(fileContainer.value);
        }
      });

      /**
       * Called when the component is unmounted.
       */
      onUnmounted(() => {
        // Disconnect the observer that watches the size of the `fileContainer'.
        fileContainerObserver.disconnect();
      });

      const scale = function (file: any, value: number): number {
        if (!fileContainerSize || !object) {
          return -1;
        }

        const { width, height } = file;

        let scaleFactor = fileContainerSize.value!.width / width;

        if ((height * scaleFactor) > fileContainerSize.value!.height) {
          scaleFactor = fileContainerSize.value!.height / height;
        }

        return value * scaleFactor;
      }

      const onAnnotationMoveStart = (event: MouseEvent): void => {
        if (!flags.isAnnotating) {
            return;
        }

        // Determine if the starting position is within the radius of the
        // remove handle.
        let x = event.offsetX - scale(file.value, annotation.x - annotation.radius);
        let y = event.offsetY - scale(file.value, annotation.y);
        if (Math.sqrt(x * x + y * y) < 8 && !flags.isEdittingAnnotation) {
            flags.annotation.isLocked = false;
            flags.isAnnotating = false;
            return;
        }

        // Determine if the starting position is within the radius of the
        // resize handle.
        x = event.offsetX - scale(file.value, annotation.x + annotation.radius);
        y = event.offsetY - scale(file.value, annotation.y);
        if (!flags.annotation.isLocked && Math.sqrt(x * x + y * y) < 8) {
            flags.annotation.isResizing = true;
            return;
        }

        // Determine if the starting position is within the radius of the
        // annotation.
        x = event.offsetX - scale(file.value, annotation.x);
        y = event.offsetY - scale(file.value, annotation.y);
        if (!flags.annotation.isLocked && Math.sqrt(x * x + y * y) < scale(file.value, annotation.radius)) {
            flags.annotation.isMoving = true;
            annotation.lastX = event.offsetX;
            annotation.lastY = event.offsetY;
            return;
        }
      };

      const onAnnotationMoveEnd = (event: MouseEvent): void => {
        if (!flags.isAnnotating) {
          return;
        }

        flags.annotation.isResizing = false;
        flags.annotation.isMoving = false;
        flags.annotation.hasMoved = false;
      };

      const onAnnotationMove = (event: MouseEvent): void => {
        if (!flags.isAnnotating) {
          return;
        }

        const scaleFactor = annotation.radius / scale(file.value, annotation.radius);

        if (flags.annotation.isResizing) {
          const newRadius = event.offsetX - scale(file.value, annotation.x);

          if (
            newRadius > 15
            // && (
            //   annotation.x - (newRadius * scaleFactor) > 45
            //   && annotation.x + (newRadius * scaleFactor) < file.value.width - 45
            //   && annotation.y - (newRadius * scaleFactor) > 45
            //   && annotation.y + (newRadius * scaleFactor) < file.value.height - 45
            // )
          ) {
            annotation.radius = newRadius * scaleFactor;
            flags.annotation.hasMoved = true;
          }
        }

        if (flags.annotation.isMoving) {
          const differenceX = (annotation.lastX - event.offsetX) * scaleFactor;
          const differenceY = (annotation.lastY - event.offsetY) * scaleFactor;

          // if (
          //     annotation.x - differenceX - annotation.radius > 45
          //     && annotation.x - differenceX + annotation.radius < file.value.width - 45
          // ) {
              flags.annotation.hasMoved = true;
              annotation.x -= differenceX;
          // }
          // if (
          //     annotation.y - differenceY - annotation.radius > 45
          //     && annotation.y - differenceY + annotation.radius < file.value.height - 45
          // ) {
              flags.annotation.hasMoved = true;
              annotation.y -= differenceY;
          // }
          annotation.lastY = event.offsetY;
          annotation.lastX = event.offsetX;
        }
      };

      const addAnnotation = (): void => {
        annotation.radius = Math.min(480, file.value.width / 4);
        annotation.lastX = 0;
        annotation.lastY = 0;
        annotation.x = file.value.width / 2;
        annotation.y = file.value.height / 2;

        flags.isEdittingAnnotation = false;
        flags.isAnnotating = true;
      };

      const styling = computed(() => {
        if ((!flags.isMounted && !loading) || !annotationTarget.value || !fileContainerSize.value) {
          return {
            annotation: {
              target: {
                top: '0',
                left: '0',
              },

              actions: {
                top: '0',
                left: '0',
              },
            },
          };
        }

        const annotationTargetStyle = {
          top: `${scale(file.value, annotation.y + annotation.radius) + 4}px`,
          left: `${scale(file.value, annotation.x)}px`,
        };

        const { height } = annotationTarget.value.getBoundingClientRect();

        const annotationActionsStyle = {
          top: `${scale(file.value, annotation.y + annotation.radius) + 4 + height + 4}px`,
          left: `${scale(file.value, annotation.x)}px`,
        };

        return {
          annotation: {
            actions: annotationActionsStyle,
            target: annotationTargetStyle,
          },
        };
      });

      const getUrlForFile = (file: any): string => {
        return `${Constants.CDN_URL}/${file.id}/${securityStore.authenticationToken}`;
      };

      onBeforeUnmount(() => {
        if (originalDescription !== description.value) {
          updateDescription({
            description: description.value,
            id: objectId,
          });
        }
      });

      return {
        fileContainerSize,
        fileContainer,

        loading,
        object,

        onDescriptionSubmit,
        descriptionSaved,
        description,

        onAnnotationMoveStart,
        onAnnotationMoveEnd,
        onAnnotationMove,
        annotationTarget,
        styling,
        annotation,
        addAnnotation,
        showDate,
        flags,
        file,

        newAnnotations,
        onAddPersonCancel,
        annotations,

        getUrlForFile,
        objectDone,

        onDeleteAnnotationClick,
        onDeleteAnnotation,
        onEditAnnotationClick,
        edittingAnnotation,
        cancelAnnotationEdit,
        confirmAnnotationEdit,

        onPersonFound,

        currentPhotoPosition,
        photo,
      };
    },

    methods: {
      getAnnotationParameters: function (file: any, annotation: any): any {
        // TODO: Get the `48' from the CSS.
        const ratio = 48 / (this.scale(file, annotation.data.radius) * 2);

        return {
          height: this.scale(file, file.height) * ratio,
          width: this.scale(file, file.width) * ratio,
          x: this.scale(file, annotation.data.x - annotation.data.radius) * ratio * -1,
          y: this.scale(file, annotation.data.y - annotation.data.radius) * ratio * -1,
        };
      },

      getObjectOrientation: function (): string {
        if (!this.object) {
          return 'landscape';
        }

        const { width, height } = this.object.files[0];

        if (height > width) {
          // return 'portrait';
        }

        return 'landscape';
      },

      scale: function (file: any, value: number): number {
        if (!this.fileContainerSize || !this.object) {
          return -1;
        }

        const { width, height } = file;

        let scaleFactor = this.fileContainerSize.width / width;

        if ((height * scaleFactor) > this.fileContainerSize.height) {
          scaleFactor = this.fileContainerSize.height / height;
        }

        return value * scaleFactor;
      },

      getScaleFactor: function (file: any): number {
        if (!this.fileContainerSize || !this.object) {
          return -1;
        }

        const { width, height } = file;

        let scaleFactor = this.fileContainerSize.width / width;

        if ((height * scaleFactor) > this.fileContainerSize.height) {
          scaleFactor = this.fileContainerSize.height / height;
        }

        return scaleFactor;
      },
    },
  });
